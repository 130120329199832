import { Params } from '@angular/router';

export interface Page {
  name?: string;
  title: string;
  url: string;
  icon: string;
  badge?: string;
  selected?: boolean;
  tenants?: string[];
  queryParams?: Params;
  hidden?: boolean;
  submenu?: Page[];
  feature?: string;
  rights?: string[];
}

export const OBSERVER_VALUE = '(max-width: 1350px)';

export enum PAGE_NAME {
  FAV = 'favorites',
  APPROVED = 'approved',
  PENDING = 'pending',
  UNASSIGNED = 'unassigned',
  JOBS = 'jobs',
}

export enum SIDENAV_MODES {
  OVER = 'over',
  SIDE = 'side',
}

export enum QUERY_PARAMS {
  STATUS = 'status',
}

export enum ICONS_VALUES {
  THUMB_UP = 'thumb_up',
  LIVE_HELS = 'live_help',
  STAR = 'star',
}

export enum ICON_COLORS {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARN = 'warn',
  DEFAULT = '',
}
