import { Env } from '@dn/config';

export const environment: Env = {
  brand: 'CUNO',
  production: true,
  tenant: 'cuno',
  tenantId: 'cuno-0f5o5',
  requiredClaims: ['driver'],
  firebase: {
    apiKey: 'AIzaSyCX-2apbR9dp2luTEMCbvtCRUPAO3vMBko',
    authDomain: 'cuno-berlin.firebaseapp.com',
    databaseURL: 'https://cuno-berlin.firebaseio.com',
    projectId: 'cuno-berlin',
    storageBucket: 'cuno-berlin.appspot.com',
    messagingSenderId: '871437449433',
    appId: '1:871437449433:web:86be03eaaf63e717a2e6c8',
    measurementId: 'G-4ZB3MRSX43',
  },
};
