import { NgModule } from '@angular/core';
import { Env } from './environment';
import { ModuleWithProviders } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import {
  CACHE_SIZE_UNLIMITED,
  FirestoreSettings,
  connectFirestoreEmulator,
  initializeFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FunctionsModule, connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';

@NgModule({
  imports: [FunctionsModule],
})
export class FirebaseProvidersModule {
  // TODO ed: see how the config is done in the SecuLex project
  /*
  static forRoot(environment: Env, shouldUseEmulator = false): ModuleWithProviders<FirebaseProvidersModule>[] {
    return [
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideFirestore(() => {
        const cacheSettings: FirestoreSettings = { cacheSizeBytes: CACHE_SIZE_UNLIMITED }; //TODO ed: set limit if needed
        const firestore = initializeFirestore(getApp(), cacheSettings);
        if (shouldUseEmulator) {
          connectFirestoreEmulator(firestore, 'localhost', 8080);
        }
        return firestore;
      }),
      provideStorage(() => getStorage()),
      provideAuth(() => getAuth()),
      provideFunctions(() => {
        const functions = getFunctions(initializeApp(environment.firebase), 'europe-west3');
        if (shouldUseEmulator) {
          connectFunctionsEmulator(functions, 'localhost', 9999);
        }
        return functions;
      }),
    ];
  }
   */
}
