import { inject, Pipe, PipeTransform } from '@angular/core';
import { Env, ENVIRONMENT } from './environment';

@Pipe({
  name: 'tenant',
  standalone: true,
})
export class TenantPipe implements PipeTransform {
  #environment: Env = inject(ENVIRONMENT);

  transform(tenant: string): boolean {
    return this.tenant === tenant;
  }

  get tenant(): string {
    return this.#environment.tenant;
  }

  get tenantId(): string {
    return this.#environment.tenantId;
  }
}
