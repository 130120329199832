import { Component, Inject } from '@angular/core';
import { Env, ENVIRONMENT } from '@dn/config';

@Component({
  selector: 'dn-app-root',
  templateUrl: 'cuno-planer-app.component.html',
})
export class CunoPlanerAppComponent {
  constructor(@Inject(ENVIRONMENT) env: Env) {
    console.log('[AppComponent] loaded with env:', env);
  }

  protected readonly status = status;
}
