import { Routes } from '@angular/router';

export const cunoRoutes: Routes = [
  {
    path: '',
    redirectTo: 'under-construction',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'under-construction' },
  {
    path: 'under-construction',
    loadChildren: () => import('@dn/shared-ui-elements').then((m) => m.UnderConstructionPageModule),
  },
];
