import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ENVIRONMENT } from '@dn/config';
import { environment } from '../environments/environment';
import { CunoPlanerAppRoutingModule } from './cuno-planer-app-routing.module';
import { CunoPlanerAppComponent } from './cuno-planer-app.component';

@NgModule({
  declarations: [CunoPlanerAppComponent],
  bootstrap: [CunoPlanerAppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, CunoPlanerAppRoutingModule],
  providers: [provideHttpClient(withInterceptorsFromDi()), { provide: ENVIRONMENT, useValue: environment }],
})
export class CunoPlanerAppModule {}
