import { InjectionToken } from '@angular/core';
import { FirebaseConfig } from './firebase-config';

export const ENVIRONMENT: InjectionToken<Env> = new InjectionToken<Env>('env');

export interface Env {
  readonly production: boolean;
  readonly tenant: string;
  readonly tenantId: string;
  readonly firebase: FirebaseConfig;
  readonly brand?: string;
  readonly debug?: boolean;
  readonly requiredClaims: string[];
  readonly features?: { [key: string]: boolean };
}
