import { FirebaseOptions } from '@firebase/app';

export interface FirebaseConfig extends FirebaseOptions {
  readonly apiKey: string;
  readonly authDomain: string;
  readonly databaseURL?: string;
  readonly projectId: string;
  readonly storageBucket: string;
  readonly messagingSenderId: string;
  readonly appId: string;
  readonly measurementId?: string;
  readonly useEmulator?: boolean;
}

/**
 * This is the firebase config that is currently only used for logins.
 * TODO ed: seems like this will not be a permanent solution
 *  because logging in in one project doesn't give the user access to resources in other projects.
 *  Unfortunately that's exactly what we need. For example we need this to protect
 *  Firestore collections/documents using rules.
 *  To solve that it is apparently necessary to implement login with custom token
 *  See: https://firebase.google.com/docs/auth/web/custom-auth
 *  See: DN-T-28
 */
export const authFirebaseConfig: FirebaseConfig = {
  apiKey: 'AIzaSyBXb9kydQC8otyj0cUMlI1ANDlnU3eAEZI',
  authDomain: 'dieser-gmbh.firebaseapp.com',
  projectId: 'dieser-gmbh',
  storageBucket: 'dieser-gmbh.appspot.com',
  messagingSenderId: '294431718219',
  appId: '1:294431718219:web:903b298363e68d2b7746ce',
};
