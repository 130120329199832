import { Inject, Injectable } from '@angular/core';
import { Env, ENVIRONMENT } from './environment';

@Injectable()
export class ConfigurationService {
  constructor(@Inject(ENVIRONMENT) private readonly env: Env) {}

  getMenuItems(): any[] {
    return [];
  }
}
